/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { get, isEmpty } from 'lodash';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import withWidth from '@material-ui/core/withWidth';
import { createIntl, createIntlCache, IntlProvider, injectIntl } from 'react-intl';
import { updateIntl } from 'react-intl-redux';
import qs from 'query-string';
import { ApolloProvider } from 'react-apollo';
import defaultLocale from '../../lib/i18n/default.json';
import translations from '../../lib/i18n/translations.json';
import ProfileCard from '../UserProfile/ProfileCard';
import PracticeInfo from '../UserProfile/PracticeInfo';
import ProfileDrawerSideBar from './ProfileDrawerSideBar';
import StudentProfileDrawerSideBar from './StudentProfileDrawerSideBar';
import MobileProfileBottomBar from './MobileProfileBottomBar';
import Strengths from '../UserProfile/Strengths';
import ChatWidget from '../../containers/ChatWidgetContainer';
import StartChatWidget from './StartChatWidget';
import { areUsersTheSame } from '../../lib/userUtils';
import { CreateChatQuery } from './ProfileDrawerQuery';
import client from '../../lib/apolloClient';
import AumInfo from '../UserProfile/AumInfo';
import { BobBanner } from '../BobBanner';
import { trackAnalyticsEvent } from '../../lib/analytics';
import { canMessageUser } from '../../lib/userUtils';
import { connect } from 'react-redux';
import PartialSaleBanner from '../PartialSaleBanner/PartialSaleBanner';

// This is optional but highly recommended
// since it prevents memory leak
const cache = createIntlCache();

const setIntl = () => {
  updateIntl(
  {
    locale   : defaultLocale,
    messages : translations[window.locale || 'en'],
  },
  cache
);}

class ProfileDrawer extends React.Component {
  constructor (props) {
    super(props);
    const { profile } = this.props;
    const { data } = profile;
    const { getUser } = data;
    this.state = {
      showChatBox : get(getUser, 'conversation_with_me.id', '') ? true : false,
      chatId      : get(getUser, 'conversation_with_me.id', ''),
      startConvo  : false,
    };
    this.recommendationRef = React.createRef();
    this.scheduleRef = React.createRef();
    this.proposalRef = React.createRef();
    this.chatRef = React.createRef();
    this.chatRefBottom = React.createRef();

    trackAnalyticsEvent('Profile View', {
      viewed_user_id : getUser.id,
    });
  }

  componentWillReceiveProps (nextProps) {
    const { profile } = this.props;
    const { data } = profile;
    const { getUser, companyHasNewMessages, companyHasNetworking } = data;
    const user = getUser;
    const nextUser = get(nextProps, 'profile.data.getUser');
    if (get(nextUser, 'id') !== get(user, 'id')) {
      this.setState({
        showChatBox : get(nextUser, 'conversation_with_me.id', '') ? true : false,
        chatId      : get(nextUser, 'conversation_with_me.id', ''),
      });
    }
    if (get(nextProps, 'profile.data.anchor', '') === 'chat') {
      if (!get(nextUser, 'conversation_with_me.id') && companyHasNewMessages && companyHasNetworking) {
        this.setState({ startConvo: true });
      }

      setTimeout(() => {
        if (this.chatRef && this.chatRef.current) {
          this.chatRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, 1000);
    }
    if (get(nextProps, 'profile.data.anchor', '') === 'notebook') {
      window.history.pushState({}, document.title, '?anchor=notebook');
    }
  }

  deSelectUser = () => {
    const { updateSelectedProfileDrawer } = this.props;
    updateSelectedProfileDrawer({});
  };

  renderSideBar = obfuscateView => {
    const {
      profile,
      updateBobCards,
      loadBobCardList,
      managedMarketplaceOnly,
      disableMessageBtn,
      getCurrentUser,
      currentUserHasSellingTransitionGoals,
      currentUserHasLimitedAccess,
      displaySocialButtons,
      updateNotes,
      notes,
      agentWordForCompany,
      updateProfile,
    } = this.props;
    const { data } = profile;
    const { companyHasNewMessages, companyHasNetworking, getUser, companyBusinessType } = data;
    const hideSideBarElements =
      get(profile, 'data.getUser.is_in_deal_past_out_for_receiving_signature_state', false) &&
      get(profile, 'data.getUser.profile_type', '') === 'implicitly_obfuscated_user';
    let disableMessageButton = disableMessageBtn;
    if (get(getCurrentUser, 'omit', false)){
      disableMessageButton = true;
    }
    return (
     get(profile, 'data.getUser.is_student', true) ? (
        <StudentProfileDrawerSideBar
          node={node => (this.node = node)}
          companyHasNewMessages={companyHasNewMessages}
          companyHasNetworking={companyHasNetworking}
          scrollTo={this.scrollTo}
          user={getUser}
          hasInterestedUsers={get(profile, 'data.getUser.expressed_interest')}
          updateBobCards={updateBobCards}
          loadBobCardList={loadBobCardList}
          currentCompanySellerWord={get(getCurrentUser, 'company.seller_only_label', 'Partner')}
          currentUserHasLimitedAccess={currentUserHasLimitedAccess}
          currentUserHasSellingTransitionGoals={currentUserHasSellingTransitionGoals}
          getCurrentUser={getCurrentUser}
          disableMessageBtn={disableMessageButton}
          marketplaceOnly={managedMarketplaceOnly}
          obfuscateView={obfuscateView}
          agentWordForCompany={agentWordForCompany}
          updateProfile={updateProfile}
          hideSideBarElements={hideSideBarElements}
        />
      ) : (
        <ProfileDrawerSideBar
          node={node => (this.node = node)}
          companyHasNewMessages={companyHasNewMessages}
          companyHasNetworking={companyHasNetworking}
          scrollTo={this.scrollTo}
          user={getUser}
          hasInterestedUsers={get(profile, 'data.getUser.expressed_interest')}
          updateBobCards={updateBobCards}
          loadBobCardList={loadBobCardList}
          currentCompanySellerWord={get(getCurrentUser, 'company.seller_only_label', 'Partner')}
          currentUserHasLimitedAccess={currentUserHasLimitedAccess}
          currentUserHasSellingTransitionGoals={currentUserHasSellingTransitionGoals}
          getCurrentUser={getCurrentUser}
          disableMessageBtn={disableMessageButton}
          marketplaceOnly={managedMarketplaceOnly}
          displaySocialButtons={displaySocialButtons}
          updateNotes={updateNotes}
          notes={get(notes, 'notes', [])}
          obfuscateView={obfuscateView}
          agentWordForCompany={agentWordForCompany}
          updateProfile={updateProfile}
          companyBusinessType={companyBusinessType}
          hideSideBarElements={hideSideBarElements}
        />
      )
    );
  };

  renderBottom = obfuscateView => {
    const {
      profile,
      updateBobCards,
      loadBobCardList,
      managedMarketplaceOnly,
      disableMessageBtn,
      getCurrentUser,
      currentUserHasSellingTransitionGoals,
      currentUserHasLimitedAccess,
      displaySocialButtons,
      updateNotes,
      notes,
      agentWordForCompany,
    } = this.props;
    const { data } = profile;
    const { companyHasNewMessages, companyHasNetworking, getUser } = data;
    let disableMessageButton = disableMessageBtn;
    if (get(getCurrentUser, 'omit', false)){
      disableMessageButton = true;
    }
    return (
      <MobileProfileBottomBar
        companyHasNewMessages={companyHasNewMessages}
        companyHasNetworking={companyHasNetworking}
        scrollTo={this.scrollTo}
        user={getUser}
        hasInterestedUsers={get(profile, 'data.getUser.expressed_interest')}
        updateBobCards={updateBobCards}
        loadBobCardList={loadBobCardList}
        currentCompanySellerWord={get(getCurrentUser, 'company.seller_only_label', 'Partner')}
        currentUserHasLimitedAccess={currentUserHasLimitedAccess}
        currentUserHasSellingTransitionGoals={currentUserHasSellingTransitionGoals}
        getCurrentUser={getCurrentUser}
        disableMessageBtn={disableMessageButton}
        marketplaceOnly={managedMarketplaceOnly}
        displaySocialButtons={displaySocialButtons}
        updateNotes={updateNotes}
        notes={get(notes, 'notes', [])}
        obfuscateView={obfuscateView}
        agentWordForCompany={agentWordForCompany}
      />
    );
  };

  hasProductMix = user =>
    !Object.values(get(user, 'product_mix') || { a: null }).every(x => x === null || x === 'ProductMix' || x === 0);

  scrollTo = ref => {
    if (ref) {
      this[ref].current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  isAdmin = () => {
    const { profile } = this.props;
    const { data } = profile;
    const { getCurrentUser } = data;
    if (!isEmpty(getCurrentUser) && getCurrentUser.roles) {
      if (getCurrentUser.roles.includes('admin')) {
        return true;
      }
    }
    return false;
  };

  createChat = async (client, user) => {
    const result = await client.mutate({
      mutation    : CreateChatQuery,
      variables   : { userName: user.fb_user_name },
      fetchPolicy : 'no-cache',
    });
    if (result.data.createChat.id) {
      this.setState({ showChatBox: true, chatId: result.data.createChat.id, startConvo: false });
    }
    setTimeout(() => {
      this.chatRefBottom.current.scrollIntoView({ behavior: 'smooth' });
    }, 1000);
  };

  render () {
    let notebook = false;
    const { location } = this.props;
    if (location) {
      const params = qs.parse(location.search);
      notebook = params.notebook;
    }
    const { showChatBox, chatId, startConvo } = this.state;
    const { classes, width } = this.props;
    const { profile } = this.props;
    const { updateBobCards, updateNotes, loadBobCardList, notes, refetch, company } = this.props;
    const {
      getCurrentUser,
      currentUserInfoAuth,
      companyHasFeature,
      countryForCompany,
      followingUser,
      availableStrengths,
      companyHasCounty,
      managedMarketplaceOnly,
      companyBusinessType,
      companyHasNewMessages,
      companyHasNetworking,
    } = profile.data;
    if (isEmpty(profile.data.getUser)) {
      return <span />;
    }
    const user = profile.data.getUser;
    const currentUser = getCurrentUser;
    const authorized = areUsersTheSame(user, currentUser) || currentUserInfoAuth;
    const ownProfile = areUsersTheSame(user, currentUser);
    const isInDealPastShortlistedState = get(user, 'is_in_deal_past_out_for_receiving_signature_state', false);
    let obfuscateView = false;
    if (!ownProfile) {
      obfuscateView =
        !currentUser.is_fb_subscriber ||
        user.profile_type === 'explicitly_obfuscated_user' ||
        (user.profile_type === 'implicitly_obfuscated_user' && !isInDealPastShortlistedState) ||
        (!user.is_pure_buyer && currentUser.limited_access) ||
        (currentUser.limited_access && isEmpty(user.transition_goals));
    }
    const checkRoles = [ 'customer', 'staff' ];
    let disableMessageBtn = false;
    if (currentUser.roles) {
      disableMessageBtn = checkRoles.some(role => currentUser.roles.includes(role));
    }
    if (get(getCurrentUser, 'omit', false)){
      enableChat = false;
      disableMessageBtn = true;
    }
    let c = company;
    if(isEmpty(c)){
      c = get(getCurrentUser, 'company');
    }
    let enableChat = canMessageUser(getCurrentUser, user, c);
    disableMessageBtn =  !enableChat;

    const stylesheetName = get(getCurrentUser, 'company.stylesheet_name');

    const canViewPartialSale = get(user, 'can_view_partial_sales', false);

    setIntl();
    return (
      <IntlProvider messages={translations[window.locale || 'en']} locale={window.locale || 'en'}>
        <Drawer
          open
          variant="temporary"
          anchor="right"
          onClose={() => this.deSelectUser()}
          classes={{
            paper : classes.drawerPaper,
          }}>
          <div className={classes.arrow}>
            <Button onClick={this.deSelectUser} style={{ width: 20 }}>
              <i className="material-icons" style={{ fontSize: 45, color: 'rgb(19, 150, 226)' }}>
                keyboard_arrow_left
              </i>
            </Button>
          </div>
          {user && (
            <div>
              <div style={{ marginBottom: width === 'xs' ? 60 : 0 }}>
                <Grid container>
                  <Grid item xs={12} sm={8}>
                    <ProfileCard
                      user={user || {}}
                      availableStrengths={availableStrengths || []}
                      currentUser={currentUser || {}}
                      showRolodex={companyHasFeature}
                      userUpdate={() => {}}
                      followingUser={followingUser}
                      currentUserInfoAuth={currentUserInfoAuth}
                      notebook={notebook}
                      countryForCompany={countryForCompany}
                      companyHasCounty={companyHasCounty}
                      obfuscateView={obfuscateView}
                      authorized={authorized}
                      enabledStudentProfiles={get(profile, "data.company.enabled_student_profile")}
                      studentWord={get(profile, "data.company.student_word")}
                      searchAmpTrainee={get(profile, "data.company.search_amp_trainee")}
                      studentProgramTitle={get(profile, "data.company.student_program_title")}
                      marketplaceOnly={managedMarketplaceOnly}
                      companyBusinessType={companyBusinessType}
                      updateNotes={updateNotes}
                      updateBobCards={updateBobCards}
                      loadBobCardList={loadBobCardList}
                      notes={get(notes, 'notes', [])}
                      refetch={refetch}
                      hideRating={!get(currentUser, 'company.ratings')}
                      isInDealPastShortlistedState={isInDealPastShortlistedState}
                    />
                    <BobBanner
                      user={user}
                      currentUser={currentUser}
                      obfuscate={currentUser.limited_access || user.obfuscated}
                    />
                    <PracticeInfo
                      user={user || {}}
                      authorized={authorized}
                      availableProducts={profile.data.availableProducts}
                      userIsAdmin={this.isAdmin()}
                      currentUserHasLimitedAccess={currentUser.limited_access}
                      ownProfile={ownProfile}
                      currentUserHasSellingTransitionGoals={currentUser.has_selling_transition_goals}
                      obfuscateView={obfuscateView}
                      companyBusinessType={companyBusinessType}
                    />
                    {canViewPartialSale && <PartialSaleBanner user={user} company={get(profile, 'data.company', {})} />}
                    {this.hasProductMix(user) && (
                      <AumInfo
                        user={user}
                        obfuscateView={obfuscateView}
                        useCanadianRetailWealthProductMix={get(user, 'company.use_canadian_retail_wealth_product_mix', false )}
                        usePAndCProductMix={get(user, 'company.use_p_and_c_product_mix', false)}
                        hideProductMix={get(user, 'company.hide_product_mix_on_profile', false)}
                      />
                    )}

                    {!isEmpty(get(user, 'tag_list')) && (
                      <Strengths
                        availableStrengths={availableStrengths || []}
                        strengths={get(user, 'tag_list')}
                        obfuscated={obfuscateView}
                        companyBusinessType={companyBusinessType}
                      />
                    )}
                    {enableChat &&
                    !disableMessageBtn &&
                    !obfuscateView && (
                      <div ref={this.chatRef}>
                        {showChatBox && (
                          <ChatWidget room_id={chatId} currentUser={currentUser} title={`${get(user, 'user_name')}`} />
                        )}
                        {!showChatBox && (
                          <div style={{ width: '100%' }}>
                            <ApolloProvider client={client}>
                              <StartChatWidget
                                userName={`${get(user, 'user_name')}`}
                                createChat={() => this.createChat(client, user)}
                                startConvo={startConvo}
                                stylesheetName={stylesheetName}
                              />
                            </ApolloProvider>
                          </div>
                        )}
                        <div ref={this.chatRefBottom} />
                      </div>
                    )}
                  </Grid>
                  {width !== 'xs' && (
                    <Grid item xs={4}>
                      {this.renderSideBar(obfuscateView)}
                    </Grid>
                  )}
                </Grid>
              </div>
              <div>
                {width === 'xs' && (
                  <div>
                    <Grid item xs={12}>
                      {this.renderBottom(obfuscateView)}
                    </Grid>{' '}
                  </div>
                )}
              </div>
            </div>
          )}
        </Drawer>
      </IntlProvider>
    );
  }
}

ProfileDrawer.propTypes = {
  classes                              : PropTypes.object.isRequired,
  getUser                              : PropTypes.object,
  profile                              : PropTypes.object.isRequired,
  data                                 : PropTypes.object,
  updateSelectedProfileDrawer          : PropTypes.func.isRequired,
  location                             : PropTypes.string,
  companyHasNewMessages                : PropTypes.bool,
  companyHasNetworking                 : PropTypes.bool,
  updateBobCards                       : PropTypes.func.isRequired,
  loadBobCardList                      : PropTypes.func.isRequired,
  currentUserHasLimitedAccess          : PropTypes.bool,
  currentUserHasSellingTransitionGoals : PropTypes.bool,
  getCurrentUser                       : PropTypes.object.isRequired,
  disableMessageBtn                    : PropTypes.bool,
  displaySocialButtons                 : PropTypes.bool,
  updateNotes                          : PropTypes.func.isRequired,
  notes                                : PropTypes.object.isRequired,
  agentWordForCompany                  : PropTypes.string.isRequired,
  managedMarketplaceOnly               : PropTypes.bool,
  width                                : PropTypes.string.isRequired,
  updateProfile                        : PropTypes.func.isRequired,
};

ProfileDrawer.defaultProps = {
  managedMarketplaceOnly               : false,
  displaySocialButtons                 : false,
  disableMessageBtn                    : false,
  currentUserHasSellingTransitionGoals : false,
  currentUserHasLimitedAccess          : false,
  companyHasNetworking                 : false,
  companyHasNewMessages                : false,
  location                             : '',
  data                                 : {},
  getUser                              : {},
};

const styles = {
  ProfileDrawer   : {
    position   : 'relative',
    display    : 'flex',
    margin     : '0 auto',
    width      : '100%',
    fontFamily : 'Arial, "Helvetica Neue", sans-serif',
    overflow   : 'scroll',
    height     : 'calc(100vh - 150px)',
  },
  column          : {
    minWidth     : 200,
    width        : '23vw',
    height       : 'auto',
    minHeight    : '100%',
    margin       : '8px',
    borderRadius : '6px 6px 0 0',
    background   : '#fafafa',
  },
  columnHead      : {
    textAlign : 'left',
    padding   : '14px 14px 8px 18px',
    fontSize  : '1.4em',
    marginTop : 12,
    display   : 'flex',
    minHeight : 68,
  },
  columnTitle     : {
    width        : 252,
    textOverflow : 'ellipsis',
    whiteSpace   : 'nowrap',
    overflow     : 'hidden',
    display      : 'inline-block',
    flex         : 1,
  },
  buttonContainer : {
    flex : 1,
  },
  item            : {
    padding  : 10,
    margin   : 10,
    fontSize : '0.8em',
    cursor   : 'pointer',
  },
  spacer          : {
    height          : 200,
    width           : '100%',
    backgroundImage : 'repeating-linear-gradient(45deg, #fff, #fff 10px, #eee 10px, #eee 20px)',
  },
  cardHeader      : {
    marginTop : 0,
  },
  valueHeader     : {
    fontSize : 16,
  },
  status          : {
    float    : 'right',
    position : 'relative',
    top      : -28,
  },
  drawerPaper     : {
    width        : 'calc(100%)',
    maxWidth     : 1120,
    height       : 'calc(100%)',
    borderRadius : 4,
    overflowY    : 'auto',
    boxShadow    : '-2px 2px 2px -1px #aaa',
    borderTop    : '1px solid #aaa',
    padding      : 20,
    background   : '#F2F4F4',
  },
  arrow           : {
    display : 'block',
  },
};

const mapStateToProps = state => ({
  company : state.company,
});

export default connect(mapStateToProps)(injectIntl(withWidth()(withStyles(styles)(ProfileDrawer))));
