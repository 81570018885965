import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { findIndex, get } from 'lodash';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import { connect } from 'react-redux';
import CheckButton from '../../CheckButton/CheckButton';
import { spotlightElement } from '../../../lib/findBobUtils';
import { injectIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { log } from 'util';

const styles = theme => ({
  formGroup  : {
    border       : '1px solid #dadada',
    padding      : '12px',
    marginTop    : '6px',
    borderRadius : '2px',
  },
  groupTitle : {
    paddingLeft : '6px',
  },
  toolTip    : {
    fontSize : 14,
  },
  red        : {
    color : 'red',
  },
  inputHidden : {
    height:0,
    width:0,
    background: 'rgba(0,0,0,0)',
    border: 'none',
    outlineWidth: 0, 
    padding:0,
    margin:0,
    float:'right',
    display: 'contents',
  },
});

class TransitionGoalSelector extends React.Component {
  state = { value: 'none' };

  render () {
    const { toggleGoal, classes, user, stylesheetName, disableSelection, adminView } = this.props;
    let { intl, intl2 } = this.props;
    const { transition_goals, company } = user;
    const { value } = this.state;
    const not_managed = !company.is_managed;
    const disableAll = user.is_branch_exception;
    const disable = !adminView && (disableSelection || disableAll || get(user, 'inventory_user.is_invisible', false) || get(user, 'is_student', false));

    if (intl2) {
      intl = intl2;
    }

    if (user.is_branch_exception && get(user, 'transition_goals', []).length === 0) {
      toggleGoal({ target: { value: 'Currently not interested in Buying / Selling' } });
    }

    if (user.is_student && get(user, 'transition_goals', []).length === 0) {
      toggleGoal({ target: { value: 'Become a Successor' } });
      setTimeout(() => {toggleGoal({ target: { value: 'Becoming a Junior Partner' } })}, 200);
    }

    return (
      <div className={classes.formGroup} onFocus={() => {}} onMouseOver={() => spotlightElement('.transition-goals')}>
        <input type="text" 
          name="user[transition_goal_names]" 
          value={transition_goals}  
          className={classes.inputHidden} 
          required 
          minlength="1" />
        {!adminView && (
          <div className={classes.groupTitle}>
            <span>
              {user.is_student 
                ? <FormattedMessage id="sign_up_pages.student_with_here_for_research"/>
                : <FormattedMessage id="sign_up_pages.choose_the_option" values={{branded_name: company.branded_name}}/>
              }
            </span>
            <br />
          </div>
        )}
        {get(user, 'transition_goals', []).length === 0 && (
          <div className={classes.groupTitle}>
            {!user.is_student && ( 
              <span style={{color: 'red'}}>Please select at least one goal</span>
            )}
          </div>
        )}
        <br />
        {!disableAll && (     
          <div>
            <div className={classes.groupTitle}>
              <b><FormattedMessage id="sign_up_pages.growth_through" /></b>&nbsp;&nbsp;
              <Tooltip
                title={
                  <div className={classes.toolTip}>
                    <h3>
                      <b><FormattedMessage id="sign_up_pages.growth_through" /></b>
                    </h3>
                    <p>
                      <b><FormattedMessage id="profile_page.transition_goal_sections.buying_a_book"/></b>
                      <FormattedMessage id="profile_page.transition_goal_sections.youre_ready_to_acquire_a_book"/>
                    </p>
                    <p>
                      <b><FormattedMessage id="profile_page.transition_goal_sections.becoming_a_successor"/></b>
                      <FormattedMessage id="profile_page.transition_goal_sections.youre_looking_to_partner"/>
                    </p>
                    <p>
                      <b><FormattedMessage id="profile_page.transition_goal_sections.forming_a_partnership"/></b>
                      <FormattedMessage id="profile_page.transition_goal_sections.youre_seeking_a_suitable_advisor"/>
                    </p>
                    <p>
                      <b><FormattedMessage id="profile_page.transition_goal_sections.finding_a_junior_advisor"/></b>
                      <FormattedMessage id="profile_page.transition_goal_sections.youre_looking_to_recruit"/>
                    </p>
                  </div>
                }
                placement="right">
                <div style={{ display: 'inline-block' }}>
                  <i className=" fas fa-question-circle" />
                </div>
              </Tooltip>
              <br />
            </div>
            <div>
              <CheckButton
                disable={disable}
                stylesheetName={stylesheetName}
                label={intl.formatMessage({
                  id : 'sign_up_pages.buying_a_book',
                })}
                checked={findIndex(transition_goals, o => o === 'Buying') > -1}
                onClick={toggleGoal}
                value="Buying"     
              />
              <CheckButton
                disable={disable}
                stylesheetName={stylesheetName}
                label={intl.formatMessage({
                  id : 'sign_up_pages.becoming_a_successor',
                })}
                checked={findIndex(transition_goals, o => o === 'Become a Successor') > -1}
                onClick={toggleGoal}
                value="Become a Successor"
              />
              <CheckButton
                disable={disable}
                stylesheetName={stylesheetName}
                label={intl.formatMessage({
                  id : 'sign_up_pages.becoming_a_junior_partner',
                })}
                checked={findIndex(transition_goals, o => o === 'Becoming a Junior Partner') > -1}
                onClick={toggleGoal}
                value="Becoming a Junior Partner"
              />
              <CheckButton
                disable={disable}
                stylesheetName={stylesheetName}
                label={intl.formatMessage({
                  id : 'sign_up_pages.forming_a_partnership',
                })}
                checked={findIndex(transition_goals, o => o === 'Forming a Partnership') > -1}
                onClick={toggleGoal}
                value="Forming a Partnership"
              />
              <CheckButton
                disable={disable}
                stylesheetName={stylesheetName}
                label={intl.formatMessage({
                  id : 'sign_up_pages.finding_a_junior_partner',
                })}
                checked={findIndex(transition_goals, o => o === 'Finding a Junior Partner') > -1}
                onClick={toggleGoal}
                value="Finding a Junior Partner"
              />
            </div>
            <br />
            <div className={classes.groupTitle}>
              <b><FormattedMessage id="sign_up_pages.planning_through" /></b>&nbsp;&nbsp;
              <Tooltip
                title={
                  <div className={classes.toolTip}>
                    <h3>
                      <b><FormattedMessage id="sign_up_pages.planning_through" /></b>
                    </h3>
                    <p>
                      <b><FormattedMessage id="profile_page.transition_goal_sections.selling_my_practice"/></b> 
                      <FormattedMessage id="profile_page.transition_goal_sections.youre_actively_looking_for_a_buyer"/><br />
                    </p>
                    <p>
                      <b><FormattedMessage id="profile_page.transition_goal_sections.selling_a_part_of_my_practice"/></b>
                      <FormattedMessage id="profile_page.transition_goal_sections.youre_not_leaving_the_business_but"/><br />
                    </p>
                    <p>
                      <b><FormattedMessage id="profile_page.transition_goal_sections.finding_a_long_term_successor"/></b>
                      <FormattedMessage id="profile_page.transition_goal_sections.youre_looking_to_partner_with_an_advisor"/><br />
                    </p>
                  </div>
                }
                placement="right">
                <div style={{ display: 'inline-block' }}>
                  <i className=" fas fa-question-circle" />
                </div>
              </Tooltip>
              <br />
            </div>
            <div>
              <CheckButton
                disable={disable}
                stylesheetName={stylesheetName}
                label={intl.formatMessage({
                  id : 'sign_up_pages.selling_my_practice',
                })}
                checked={findIndex(transition_goals, o => o === 'Selling my practice') > -1}
                onClick={toggleGoal}
                value="Selling my practice"
              />
              <CheckButton
                disable={disable}
                stylesheetName={stylesheetName}
                label={intl.formatMessage({
                  id : 'sign_up_pages.selling_part_of_my_practice',
                })}
                checked={findIndex(transition_goals, o => o === 'Selling part of my practice') > -1}
                onClick={toggleGoal}
                value="Selling part of my practice"
              />
              <CheckButton
                disable={disable}
                stylesheetName={stylesheetName}
                label={intl.formatMessage({
                  id : 'sign_up_pages.finding_a_successor',
                })}
                checked={findIndex(transition_goals, o => o === 'Find a Successor') > -1}
                onClick={toggleGoal}
                value="Find a Successor"
              />
            </div>
            {not_managed && (
              <span>
                <CheckButton
                  disable={disable}
                  stylesheetName={stylesheetName}
                  label={intl.formatMessage({
                    id : 'sign_up_pages.partnering',
                  })}
                  checked={findIndex(transition_goals, o => o === 'Partnering') > -1}
                  onClick={toggleGoal}
                  value="Partnering"
                />
                <CheckButton
                  disable={disable}
                  stylesheetName={stylesheetName}
                  label={intl.formatMessage({
                    id : 'sign_up_pages.merging',
                  })}
                  checked={findIndex(transition_goals, o => o === 'Merging') > -1}
                  onClick={toggleGoal}
                  value="Merging"
                />

                <CheckButton
                  disable={disable}
                  stylesheetName={stylesheetName}
                  label={intl.formatMessage({
                    id : 'sign_up_pages.mentoring',
                  })}
                  checked={findIndex(transition_goals, o => o === 'Mentoring') > -1}
                  onClick={toggleGoal}
                  value="Mentoring"
                />
                <CheckButton
                  disable={disable}
                  stylesheetName={stylesheetName}
                  label={intl.formatMessage({
                    id : 'sign_up_pages.being_mentored',
                  })}
                  checked={findIndex(transition_goals, o => o === 'Being Mentored') > -1}
                  onClick={toggleGoal}
                  value="Being Mentored"
                />
              </span>
            )}
            <br />
          </div>
        )}
        {company.business_type === 'wealth_rj' && (
          <Fragment>
            {get(user, 'inventory_user.is_invisible', false) &&
            !adminView && !user.is_student && (
              <div className={classes.red}>
                <FormattedMessage id="sign_up_pages.based_on_your_role" />
              </div>
            )}
            <div className={classes.groupTitle}>
              <b><FormattedMessage id="sign_up_pages.here_for_research" /></b>&nbsp;&nbsp;
              <Tooltip
                title={
                  <div className={classes.toolTip}>
                    <h3><FormattedMessage id="sign_up_pages.here_for_research" /></h3>
                    <p>
                      <b><FormattedMessage id="profile_page.transition_goal_sections.currently_not_interested_in_buying"/></b>
                      <FormattedMessage id="profile_page.transition_goal_sections.youd_like_to_access_branded_name" values={{ branded_name: company.branded_name }} />
                      <br />
                      <FormattedMessage id="profile_page.transition_goal_sections.note_you_can_always_change"/>
                    </p>
                  </div>
                }
                placement="right">
                <div style={{ display: 'inline-block' }}>
                  <i className=" fas fa-question-circle" />
                </div>
              </Tooltip>
              <br />
            </div>

            <CheckButton
              disable={disableSelection || get(user, "is_student", false)}
              stylesheetName={stylesheetName}
              label={intl.formatMessage({ id : 'sign_up_pages.not_interested' })}
              checked={findIndex(transition_goals, o => o === 'Currently not interested in Buying / Selling') > -1}
              onClick={toggleGoal}
              value="Currently not interested in Buying / Selling"
            />
          </Fragment>
        )}
      </div>
    );
  }
}
TransitionGoalSelector.propTypes = {
  transition_goals : PropTypes.array.isRequired,
  toggleGoal       : PropTypes.func.isRequired,
  classes          : PropTypes.object.isRequired,
  intl             : PropTypes.object.isRequired,
  disableSelection : PropTypes.bool,
  adminView        : PropTypes.bool,
};

TransitionGoalSelector.defaultProps = {
  disableSelection : false,
  adminView        : false,
};

const mapStateToProps = state => ({ user: state.user });

const TransitionGoalSelectorWithStyles = withStyles(styles)(connect(mapStateToProps)(TransitionGoalSelector));
export default injectIntl(TransitionGoalSelectorWithStyles);
