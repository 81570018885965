import React, { useState } from 'react';
import { TextField, Select, Button, MenuItem, Typography, InputLabel, FormControl, FormLabel } from '@material-ui/core';
import { format } from 'path-browserify';

const interestsOptions = [
  { value: 'acquireOtherPractices', label: 'I want to acquire other practices' },
  { value: 'sellMyBusiness', label: 'I want to sell my business' },
  { value: 'seekingGrowthCapital', label: 'I am seeking growth capital' },
  { value: 'seekingASuccessor', label: 'I am seeking a successor' },
  { value: 'growingMyPractice', label: 'I am interested in growing my practice’s valuation' },
  { value: 'understandValuation', label: 'Better understand valuation' },
];

const states = [
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'DC', label: 'District Of Columbia' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' },
];

const provinces = [
  { value: 'AB', label: 'Alberta' },
  { value: 'BC', label: 'British Columbia' },
  { value: 'MB', label: 'Manitoba' },
  { value: 'NB', label: 'New Brunswick' },
  { value: 'NL', label: 'Newfoundland and Labrador' },
  { value: 'NS', label: 'Nova Scotia' },
  { value: 'ON', label: 'Ontario' },
  { value: 'PE', label: 'Prince Edward Island' },
  { value: 'QC', label: 'Quebec' },
  { value: 'SK', label: 'Saskatchewan' },
  { value: 'NT', label: 'Northwest Territories' },
  { value: 'NU', label: 'Nunavut' },
  { value: 'YT', label: 'Yukon' },
];

const StepInput = ({ onChange, onClick, calculateDCF, calculateMOR, companyCountry }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [city, setCity] = useState('');
  const [province, setProvince] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [postalCodeError, setPostalCodeError] = useState('');
  const [yearsInBusiness, setYearsInBusiness] = useState('');
  const [interests, setInterests] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handleBlur = (event) => {
    const { name, value } = event.target;
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    const phoneNumberPattern = /^\d{10}$/;
    const postalCodePattern = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
    const zipCodePattern = /^\d{5}$/;
    let valuation = true;
  
    if (name === 'email' && !emailPattern.test(value)) {
      valuation = false;
      setEmailError('Invalid email address');
    } else if (name === 'email' && emailPattern.test(value)) {
      setEmailError('');
    } else if (name === 'phone_number' && !phoneNumberPattern.test(value)) {
      valuation = false;
      setPhoneNumberError('Invalid phone number');
    } else if (name === 'phone_number' && phoneNumberPattern.test(value)) {
      setPhoneNumberError('');
    } else if (name === 'postal_code') {
      console.log('companyCountry', companyCountry);
      if (companyCountry === 'us') {
        if (name === 'postal_code' && !zipCodePattern.test(value)) {
          valuation = false;
          setPostalCodeError('Invalid zip code');
        } else if (name === 'postal_code' && zipCodePattern.test(value)) {
          setPostalCodeError('');
        }
      } else {
        if (name === 'postal_code' && !postalCodePattern.test(value)) {
          valuation = false;
          setPostalCodeError('Invalid postal code');
        } else if (name === 'postal_code' && postalCodePattern.test(value)) {
          setPostalCodeError('');
        }
      }
    } else {
      valuation = true;
    }

    if (name.length > 0 && email.length > 0 && phoneNumber.length > 0 && city.length > 0 && province.length > 0 && postalCode.length > 0 && yearsInBusiness !== null && interests !== null && valuation) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  };

  const handleNumberChange = (event) => {
    const { name, value } = event.target;
    const numericValue = value.replace(/[^\d]/g, '');
  
    if (!numericValue || numericValue === '0') {
      onChange({ target: { name, value: 0 } });
      return;
    }

    let formattedValue;
    if (name === 'years_in_business') {
      formattedValue = numericValue;
    }

    switch (name) {
      case 'years_in_business':
        setYearsInBusiness(formattedValue);
        break;
      default:
        break;
    }

    onChange({ target: { name, value: parseInt(numericValue, 10) } });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    switch (name) {
      case 'name':
        setName(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'phone_number':
        setPhoneNumber(value);
        break;
      case 'city':
        setCity(value);
        break;
      case 'province':
        setProvince(value);
        break;
      case 'postal_code':
        setPostalCode(value);
        break;
      case 'years_in_business':
        setYearsInBusiness(value);
        break;
      case 'interests':
        setInterests(value);
        break;
      default:
        break;
    }

    onChange({ target: { name, value } });
  }

  return (
    <div>
      <Typography variant="h4" gutterBottom>I'd like a free valuation</Typography>
      <Typography variant="h6" gutterBottom>Enter Personal Info:</Typography>
      
      <FormControl fullWidth margin="normal">
      <FormLabel style={{ marginBottom: '8px', textAlign: 'left' }}>Name: </FormLabel>
        <TextField
          style={{ marginBottom: '8px' }}
          name="name"
          value={name}
          onChange={handleChange}
          onBlur={handleBlur}
          variant="outlined"
          required
        />
      <FormLabel style={{ marginBottom: '8px', textAlign: 'left' }}>Email: </FormLabel>
        <TextField
          style={{ marginBottom: '8px' }}
          name="email"
          value={email}
          onChange={handleChange}
          onBlur={handleBlur}
          variant="outlined"
          required
          error={!!emailError}
          helperText={emailError}
        />
      <FormLabel style={{ marginBottom: '8px', textAlign: 'left' }}>Phone: </FormLabel>
        <TextField
          style={{ marginBottom: '8px' }}
          name="phone_number"
          value={phoneNumber}
          onChange={handleChange}
          onBlur={handleBlur}
          variant="outlined"
          required
          error={!!phoneNumberError}
          helperText={phoneNumberError}
        />
      <FormLabel style={{ marginBottom: '8px', textAlign: 'left' }}>
        {companyCountry === 'us' ? 'State: ' : 'Province: '}
      </FormLabel>
      <Select
        style={{ marginBottom: '8px' }}
        name="province"
        value={province}
        onChange={handleChange}
        onBlur={handleBlur}
        variant="outlined"
        required
        fullWidth
      >
        {companyCountry === 'us'
          ? states.map((state) => (
              <MenuItem key={state.value} value={state.value}>
                {state.label}
              </MenuItem>
            ))
          : provinces.map((province) => (
              <MenuItem key={province.value} value={province.value}>
                {province.label}
              </MenuItem>
            ))}
      </Select>
      <FormLabel style={{ marginBottom: '8px', textAlign: 'left' }}>City: </FormLabel>
        <TextField
          style={{ marginBottom: '8px' }}
          name="city"
          value={city}
          onChange={handleChange}
          onBlur={handleBlur}
          variant="outlined"
          required
        />
      <FormLabel style={{ marginBottom: '8px', textAlign: 'left' }}>
        {companyCountry === 'us' ? 'Zip: ' : 'Postal Code: '}
      </FormLabel>
      <TextField
        style={{ marginBottom: '8px' }}
        name="postal_code"
        value={postalCode}
        onChange={handleChange}
        onBlur={handleBlur}
        variant="outlined"
        required
        error={!!postalCodeError}
        helperText={postalCodeError}
      />
      <FormLabel style={{ marginBottom: '8px', textAlign: 'left' }}>Years in business?</FormLabel>
        <TextField
          style={{ marginBottom: '8px' }}
          name="years_in_business"
          value={yearsInBusiness}
          onChange={handleNumberChange}
          onBlur={handleBlur}
          variant="outlined"
          required
        />
      <FormLabel style={{ marginBottom: '8px', textAlign: 'left' }}>What Are You Interested in Accomplishing?</FormLabel>
        <select
          name="interests"
          value={interests}
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth
        >
          <option>
            select one 
          </option>
          {interestsOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </FormControl>
      <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
        <Button variant="contained" color="primary" onClick={() => {
          calculateDCF();
          calculateMOR();
          onClick();
        }} disabled={isButtonDisabled}>
          Get Started!
        </Button>
      </div>
    </div>
  );
};

export default StepInput;