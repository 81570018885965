import React, { useState } from 'react';
import { TextField, InputAdornment, Button, Typography, FormControl, FormLabel } from '@material-ui/core';

const StepInput = ({ onChange, onClick, totalAssets, companyCountry }) => { 

  const formatToCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
    }).format(value);
  };

  const [assetsUnderManagement, setAssetsUnderManagement] = useState(formatToCurrency(totalAssets));
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [numberOfClients, setNumberOfClients] = useState('');
  const [grossRevenue, setGrossRevenue] = useState(formatToCurrency(0));
  const [persentageOfFee, setPersentageOfFee] = useState(0);
  const [persentageOfGrowthRate, setPersentageOfGrowthRate] = useState(0);
  const [persentageOfEarningsBeforeTax, setPersentageOfEarningsBeforeTax] = useState(0);
  const [persentageOfOwnership, setPersentageOfOwnership] = useState(0);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const numericValue = value.replace(/[^\d]/g, '');
  
    if (!numericValue || numericValue === 0) {
      onChange({ target: { name, value: 0 } });
      return;
    }
  
    let formattedValue;
    if (name === 'number_of_clients') {
      formattedValue = numericValue;
    } else if (name === 'persentage_of_fee' || name === 'persentage_of_growth_rate' || name === 'persentage_of_earnings_before_tax' || name === 'persentage_of_ownership') {
      const percentageValue = parseInt(numericValue, 10);
      if (isNaN(percentageValue) || percentageValue < 1 || percentageValue > 100) {
        onChange({ target: { name, value: numericValue } });
        return;
      }
      formattedValue = `${percentageValue}%`;
    } else {
      formattedValue = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
      }).format(numericValue);
    }
  
    switch (name) {
      case 'assets_under_management':
        setAssetsUnderManagement(formattedValue);
        break;
      case 'number_of_clients':
        setNumberOfClients(numericValue);
        break;
      case 'gross_revenue':
        setGrossRevenue(formattedValue);
        break;
      case 'persentage_of_fee':
        setPersentageOfFee(formattedValue);
        break;
      case 'persentage_of_growth_rate':
        setPersentageOfGrowthRate(formattedValue);
        break;
      case 'persentage_of_earnings_before_tax':
        setPersentageOfEarningsBeforeTax(formattedValue);
        break;
      case 'persentage_of_ownership':
        setPersentageOfOwnership(formattedValue);
        break;
    }
  
    const allValues = [
      assetsUnderManagement,
      numberOfClients,
      grossRevenue,
      persentageOfFee,
      persentageOfGrowthRate,
      persentageOfEarningsBeforeTax,
      persentageOfOwnership
    ];

    const allNotNullOrZero = allValues.every(value => value !== null && value !== 0);
  
    setIsButtonDisabled(!allNotNullOrZero);
    onChange({ target: { name, value: numericValue } });
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>Know Your Value</Typography>
      <Typography variant="h6" gutterBottom>Answer 7 questions that will help our calculator asses your firm's market value.</Typography>

      <FormControl fullWidth margin="normal">
        <FormLabel style={{ marginBottom: '8px', textAlign: 'left' }}>What is your firm's total AUM?</FormLabel>
          <TextField
            style={{ marginBottom: '8px' }}
            name="assets_under_management"
            value={assetsUnderManagement}
            onChange={handleChange}
            variant="outlined"
            required
          />
        <FormLabel style={{ marginBottom: '8px', textAlign: 'left' }}>How many clients do you have?</FormLabel>
          <TextField
            style={{ marginBottom: '8px' }}
            name="number_of_clients"
            value={numberOfClients}
            onChange={handleChange}
            variant="outlined"
            required
          />
        <FormLabel style={{ marginBottom: '8px', textAlign: 'left' }}>What is your firm's gross revenue?</FormLabel>
          <TextField
            style={{ marginBottom: '8px' }}
            name="gross_revenue"
            value={grossRevenue}
            onChange={handleChange}
            variant="outlined"
            required
          />
        <FormLabel style={{ marginBottom: '8px', textAlign: 'left' }}>What % of revenue is from fees and/or trailer?</FormLabel>
          <TextField
            style={{ marginBottom: '8px' }}
            name="persentage_of_fee"
            value={persentageOfFee}
            onChange={handleChange}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            variant="outlined"
            required
          />
        <FormLabel style={{ marginBottom: '8px', textAlign: 'left' }}>What is your firm's anunal growth rate?</FormLabel>
          <TextField
            style={{ marginBottom: '8px' }}
            name="persentage_of_growth_rate"
            value={persentageOfGrowthRate}
            onChange={handleChange}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            variant="outlined"
            required
          />
        <FormLabel style={{ marginBottom: '8px', textAlign: 'left' }}>What is your firm's operating profit?</FormLabel>
          <TextField
            style={{ marginBottom: '8px' }}
            name="persentage_of_earnings_before_tax"
            value={persentageOfEarningsBeforeTax}
            onChange={handleChange}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            variant="outlined"
            required
          />
        <FormLabel style={{ marginBottom: '8px', textAlign: 'left' }}>What % of ownership do you have in your firm?</FormLabel>
          <TextField
            style={{ marginBottom: '8px' }}
            name="persentage_of_ownership"
            value={persentageOfOwnership}
            onChange={handleChange}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            variant="outlined"
            required
          />
      </FormControl>
      <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
        <Button variant="contained" color="primary" onClick={() => {
          companyCountry();
          onClick();
        }} disabled={isButtonDisabled}>
          Calculate
        </Button>
      </div>
    </div>
  );
};

export default StepInput;