import React from 'react';
import Typography from '@material-ui/core/Typography';


const StepInput = ({ persentageOfOwnership, valueDCF, valueMOR }) => {
  const bigValue = Math.max(valueDCF, valueMOR).toFixed(2);
  const smallValue = Math.min(valueDCF, valueMOR).toFixed(2);
  const bigValuePer = (bigValue * (persentageOfOwnership / 100)).toFixed(2);
  const smallValuePer = (smallValue * (persentageOfOwnership / 100)).toFixed(2);
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Your Indication of Value
        </Typography>
        <Typography variant="h6" gutterBottom style={{ color: 'green' }}>
          <div>
            $ {smallValuePer} -
            $ {bigValuePer}
          </div>
        </Typography>
        <Typography variant="h6" gutterBottom>
          As a {persentageOfOwnership}% owner your value for your ownership would be between <span style={{ color: 'green' }}>$ {smallValuePer}</span> and <span style={{ color: 'green' }}>$ {bigValuePer}</span>
        </Typography>
        <Typography variant="h6" gutterBottom>
          Potential acquisition opportunities in your area
        </Typography>
        <Typography variant="h6" gutterBottom style={{ color: 'green' }}>#___</Typography>
        <br />
        <Typography variant="h4" gutterBottom>Take The Next Step</Typography>
        <Typography variant="h6" gutterBottom>Want to grow this valuation? Ready to buy more books? Looking for a successor? Our team can help.</Typography>
    </div>
  );
};

export default StepInput;