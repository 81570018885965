import React, { useState } from 'react';
import { TextField, InputAdornment, Button, Typography, FormControl, FormLabel } from '@material-ui/core';

const StepInput = ({ onChange, onClick }) => {
  const [totalAssets, setTotalAssets] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handleChange = (event) => {
    const { value } = event.target;
    const numericValue = value.replace(/[^\d]/g, '');

    if (!numericValue || numericValue === '0') {
      setTotalAssets('');
      setIsButtonDisabled(true);
      onChange({ target: { name: 'assets_under_management', value: 0 } });
      return;
    }

    const formattedValue = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
    }).format(numericValue);

    setTotalAssets(formattedValue);
    setIsButtonDisabled(false);
    onChange({ target: { name: 'assets_under_management', value: parseInt(numericValue, 10) } });
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>What is my Business worth</Typography>
      <Typography variant="h6" gutterBottom>Start by assessing the value of your business:</Typography>
      
      <FormControl fullWidth margin="normal">
        <FormLabel style={{ marginBottom: '8px', textAlign: 'left' }}>What is your firm's total AUM?</FormLabel>
        <TextField
          name="assets_under_management"
          value={totalAssets}
          onChange={handleChange}
          variant="outlined"
          required
        />
      </FormControl>
      <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
        <Button variant="contained" color="primary" onClick={onClick} disabled={isButtonDisabled}>
          Get my free Indication of Value
        </Button>
      </div>
    </div>
  );
};

export default StepInput;