// ValuationWidget.js

import React, { Component } from 'react';
import { Button, Paper, Box, Stepper, Step, StepLabel } from '@material-ui/core';
import { post } from '../../lib/api';
import ValuationStep1 from './ValuationStep1';
import ValuationStep2 from './ValuationStep2';
import ValuationStep3 from './ValuationStep3';
import ValuationStep4 from './ValuationStep4';

const steps = ['Assets', 'Info', 'Submit', ' '];

class ValuationWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      formData: { 
        name: '',
        email: '',
        phone_number: '',
        city: '',
        province: '',
        postal_code: '',
        interests: '',
        total_assets: 0,
        years_in_business: 0,
        assets_under_management: 0,
        number_of_clients: 0,
        gross_revenue: 0,
        persentage_of_fee: 0,
        persentage_of_growth_rate: 0,
        persentage_of_earnings_before_tax: 0,
        persentage_of_ownership: 0,
      },
      dcfValue: 0,
      morValue: 0,
      companyCountry: '',
      widgetHeight: null,  
    };
    this.widgetRef = React.createRef();
  }

  componentDidMount() {
    // Listen for messages from parent window
    window.addEventListener('message', this.handleMessage);
    // Send initial height to parent window
    this.sendHeight();
  }

  componentWillUnmount() {
    // Clean up event listener
    window.removeEventListener('message', this.handleMessage);
  }

  handleMessage = (event) => {
    // Handle messages from parent window, if needed
    // Example: Respond to parent messages
    if (event.data.type === 'setHeight') {
      this.sendHeight();
    }
  };

  getCountry = async () => {
    const { formData } = this.state;
    try {
      const response = await fetch('/widgets/get_company_country', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ form_data: formData }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      const companyCountry = data.country;
      this.setState({ companyCountry });
    } catch (error) {
      console.error('Error getting company country:', error);
    }
  };

  calculateDCF = async () => {
    const { formData, dcfValue } = this.state;
    try {
      const response = await fetch('/widgets/calculate_dcf', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ form_data: formData }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      let data = await response.json();
      const dcfValue = parseInt(data.dcf_value.value_of_practice, 10);
      this.setState({ dcfValue });
      // Handle the DCF value as needed
    } catch (error) {
      console.error('Error calculating DCF:', error);
    }
  };

  calculateMOR = async () => {
    const { formData } = this.state;
    try {
      const response = await fetch('/widgets/calculate_mor', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ form_data: formData }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      const morValue = data.mor_value;
      this.setState({ morValue });
      // Handle the MOR value as needed
    } catch (error) {
      console.error('Error calculating MOR:', error);
    }
  };

  sendHeight = () => {
    // Send current widget height to parent window
    let height = this.widgetRef.current.scrollHeight;
    switch (this.state.activeStep) {
      case 0:
        height = '360px';
        break;
      case 1:
        height = '840px';
        break;
      case 2:
        height = '870px';
        break;
      case 3:
        height = '560px';
        break;
      default:
        break;
    }

    this.setState({ widgetHeight: height });
    window.parent.postMessage({ type: 'resize', height }, '*');

  };

  handleNext = () => {
    // Move to the next step and send updated height to parent
    if (this.state.activeStep === steps.length - 1) {
      this.handleSubmit();
    } else {
      this.setState((prevState) => ({ activeStep: prevState.activeStep + 1 }), this.sendHeight);
    }
  };

  handleBack = () => {
    // Move to the previous step and send updated height to parent
    this.setState((prevState) => ({ activeStep: prevState.activeStep - 1 }), this.sendHeight);
  };

  handleChange = (event) => {
      const value = event.target.value;
      const parsedValue = isNaN(parseInt(value, 10)) ? value : parseInt(value, 10);

      this.setState({
        formData: { ...this.state.formData, [event.target.name]: parsedValue },
      });
  };

  handleSubmit = async () => {
    // Submit form data and send updated height to parent
    try {
      const response = await post('/widgets/submit_valuation', { form_data: this.state.formData });
      console.log(response.data.message);
    } catch (error) {
      console.error("There was an error submitting the form!", error);
    }
    this.sendHeight();
  };

  renderStepContent = (step) => {
    // Render step content based on active step
    switch (step) {
      case 0:
        return (
          <div style={{ width: '450px'}}>
            <ValuationStep1
              name="totalAssets"
              totalAssets={this.state.formData.assets_under_management}
              onChange={this.handleChange}
              onClick={this.handleNext}
            />
          </div>
        );
      case 1:
        return (
          <div style={{ width: '450px'}}>
            <ValuationStep2
              totalAssets={this.state.formData.assets_under_management}
              assetsUnderManagement={this.state.formData.assets_under_management}
              numberOfClients={this.state.formData.number_of_clients}
              grossRevenue={this.state.formData.gross_revenue}
              persentageOfFee={this.state.formData.persentage_of_fee}
              persentageOfGrowthRate={this.state.formData.persentage_of_growth_rate}
              persentageOfEarningsBeforeTax={this.state.formData.persentage_of_earnings_before_tax}
              persentageOfOwnership={this.state.formData.persentage_of_ownership}
              onChange={this.handleChange}
              onClick={this.handleNext}
              companyCountry={this.getCountry}
            />
          </div>
        );
      case 2:
        return (
          <div style={{ width: '450px'}}>
            <ValuationStep3
              name={this.state.formData.name}
              email={this.state.formData.email}
              phoneNumber={this.state.formData.phone_number}
              city={this.state.formData.city}
              province={this.state.formData.province}
              postalCode={this.state.formData.postal_code}
              yearsInBusiness={this.state.formData.years_in_business}
              interests={this.state.formData.interests}
              companyCountry={this.state.companyCountry}
              onChange={this.handleChange}
              onClick={this.handleNext}
              calculateDCF={this.calculateDCF}
              calculateMOR={this.calculateMOR}
            />
          </div>
        );
      case 3:
        return (
          <div style={{ width: '450px'}}>
            <ValuationStep4
              persentageOfOwnership={this.state.formData.persentage_of_ownership}
              valueDCF={this.state.dcfValue}
              valueMOR={this.state.morValue}
              onChange={this.handleChange}
            />
            <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
              <Button variant="contained" color="primary" onClick={this.handleNext}>
                Get Started
              </Button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  render() {
    return (
      <div ref={this.widgetRef}>
        <Paper elevation={3} style={{ padding: '20px', maxWidth: '800px', margin: 'auto' }}>
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center">
            <Stepper activeStep={this.state.activeStep}>
              {steps.map((label) => (
                <Step style={{ paddingLeft: 0 }} key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div>
              {this.renderStepContent(this.state.activeStep)}
            </div>
          </Box>
        </Paper>
      </div>
    );
  }
}

export default ValuationWidget;
